import React, { useRef } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { PrivateRoute } from 'marvin-auth-kit';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MaintenanceContainer } from './MaintenanceContainer';
import { AuthLayout } from '../layouts/AuthLayout';
import { LoginContainer } from './LoginContainer';

interface Props {}

export function RootContainer(props: Props) {
  const notistackRef = useRef<SnackbarProvider | null>(null);
  function onClickDismiss(key: SnackbarKey) {
    if (notistackRef !== null && notistackRef.current !== null) {
      notistackRef.current.closeSnackbar(key);
    }
  }

  const publicRoutes = [
    '/register',
    '/login',
    '/forgot-password',
    '/reset-password',
  ];

  return render();

  function render() {
    return (
      <SnackbarProvider
        maxSnack={3}
        ref={notistackRef}
        action={(key: SnackbarKey) => (
          <IconButton onClick={() => onClickDismiss(key)}>
            <Close />
          </IconButton>
        )}
      >
        <Switch>
          <Route path={publicRoutes}>
            <AuthLayout>
              <Switch>
                <Route path="/login" component={LoginContainer} />
                {/* <Route
                path="/forgot-password"
                component={ForgotPasswordContainer}
              /> */}
                {/* <Route
                path="/reset-password"
                component={ResetPasswordContainer}
              /> */}
              </Switch>
            </AuthLayout>
            );
          </Route>
          <PrivateRoute
            path={['/maintenance']}
            component={MaintenanceContainer}
          />
          <Redirect to="/login" />
        </Switch>
      </SnackbarProvider>
    );
  }
}
