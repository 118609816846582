import React from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik, Field, FieldAttributes, FormikHelpers } from 'formik';
import { auth, parseServerError } from 'marvin-auth-kit';
import { trim } from 'lodash';
import { useSnackbar } from 'notistack';

interface Credentials {
  username: string;
  password: string;
}

interface Props {}

export function LoginContainer(props: Props) {
  const history = useHistory();
  const location = useLocation<{ from: { pathname: string } }>();
  const { enqueueSnackbar } = useSnackbar();

  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('gebruikersnaam is verplicht'),
    password: Yup.string().required('wachtwoord is verplicht'),
  });

  // functions

  async function onSubmit(
    fields: Credentials,
    helpers: FormikHelpers<Credentials>
  ) {
    const { setSubmitting } = helpers;
    try {
      const result = await auth.login(fields.username, trim(fields.password));

      if (result.isOk()) {
        setSubmitting(false);
        redirectAfterSuccess();
      } else {
        enqueueSnackbar(
          parseServerError(
            result.error.shift() || {
              key: "invalid",
              message: "Invalid login",
              arguments: [],
            }
          ),
          { variant: "error" }
        );
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
    setSubmitting(false);
  }

  function redirectAfterSuccess() {
    if (location.state && location.state.from.pathname !== '/login') {
      history.push(location.state.from.pathname);
    } else {
      history.push('/maintenance');
    }
  }

  return render();

  function render() {
    return (
      <Styles>
        <Typography variant="h3" component="h1">
          Welkom
        </Typography>
        <Typography variant="subtitle1" component="h1">
          Meld je aan om verder te gaan
        </Typography>

        <Formik
          initialValues={{ username: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, handleSubmit, getFieldProps }) => (
            <form onSubmit={handleSubmit} className="formContainer">
              <Field name="username">
                {({
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                }: FieldAttributes<any>) => (
                  <div>
                    <TextField
                      label="Gebruikersnaam"
                      error={Boolean(errors.username && touched.username)}
                      helperText={
                        errors.username &&
                        touched.username &&
                        String(errors.username)
                      }
                      margin="normal"
                      {...getFieldProps('username')}
                    />
                  </div>
                )}
              </Field>

              <Field name="password">
                {({
                  field, // { name, value, onChange, onBlur }
                  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }: FieldAttributes<any>) => (
                  <div>
                    <TextField
                      label="Wachtwoord"
                      type="password"
                      error={Boolean(errors.password && touched.password)}
                      helperText={
                        errors.password &&
                        touched.password &&
                        String(errors.password)
                      }
                      margin="normal"
                      {...getFieldProps('password')}
                    />
                  </div>
                )}
              </Field>

              <div className="buttonGroup">
                <Button
                  className="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {!isSubmitting && 'Login'}
                  {isSubmitting && (
                    <CircularProgress size={24} color="secondary" />
                  )}
                </Button>

                <Button variant="text" disabled={isSubmitting}>
                  Wachtwoord vergeten
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Styles>
    );
  }
}

const Styles = styled.div`
  ${({ theme }) => `
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  box-sizing: border-box;
  justify-content: center;

  .buttonGroup {
    margin: ${theme.spacing(3)}px 0;
    > * {
      margin-right: ${theme.spacing(1)}px;
    }
  }
  `}
`;
