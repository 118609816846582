import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router';

export function MaintenanceSuccess(props: {}) {
  const history = useHistory();
  return (
    <Styles>
      <Typography variant="h5">Success</Typography>
      <Box my={4}>
        <Typography variant="body1">
          Het formulier werd successvol verzonden.
        </Typography>
      </Box>

      <Button
        variant="contained"
        onClick={() => {
          history.push('/maintenance');
        }}
      >
        Opnieuw
      </Button>
    </Styles>
  );
}

const Styles = styled.div`
  background: inherit;
  padding: 20px;
`;
