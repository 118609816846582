import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import bg from './../assets/images/auth-bg2.jpg';

interface Props {
  children: React.ReactElement;
}

export const AuthLayout = (props: Props) => {
  return (
    <Styles>
      <Grid container component="main" className="root">
        <Grid item xs={false} sm={4} md={7} className="cover" />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {props.children}
        </Grid>
      </Grid>
    </Styles>
  );
};

const Styles = styled.div`
  position: relative;
  height: 100%;

  .root {
    height: 100%;
  }
  .cover {
    background-image: url(${bg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
