import React, { useMemo, useState } from 'react';
import { Marker, TileLayer } from 'react-leaflet';
import useSWR from 'swr';
import { Map } from 'marvin-ui-kit';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import axios from 'axios';
import { AppLayout } from '../layouts/AppLayout';
import { MaintenanceForm } from '../components/MaintenanceForm';
import { Protocol, Sensor } from '../types';
import { dummyProtocol } from '../constants';
import { map, uniq } from 'lodash';
import { Route, useHistory } from 'react-router';
// @ts-ignore
import { readAndCompressImage } from 'browser-image-resizer';
import { MaintenanceSuccess } from '../components/MaintenanceSuccess';

export function MaintenanceContainer() {
  const history = useHistory();
  const { data: sensors } = useSWR<Sensor[]>('/api/sensors', {
    suspense: true,
    revalidateOnFocus: false,
  });

  const [activeLocation, setActiveLocation] = useState<string | null>(null);
  const [activeSensor, setActiveSensor] = useState<Sensor | null>(null);
  const [activeProtocol, setActiveProtocol] = useState<Protocol | null>(null);

  const locations: string[] = useMemo(() => {
    return uniq(map(sensors, 'locationShortName'));
  }, [sensors]);

  const filteredSensors = useMemo(() => {
    return sensors?.filter(
      (sensor) => sensor.locationShortName === activeLocation
    );
  }, [activeLocation, sensors]);

  const [lat, lng, zoom] = process.env
    .REACT_APP_INITIAL_POSITION!.split(',')
    .map(parseFloat);
  const position = { lat, lng, zoom };

  function handleMarkerClick(sensor: Sensor) {
    setActiveLocation(sensor.locationShortName);
    setActiveSensor(sensor);
  }

  async function handleSubmit(
    sensor: Sensor,
    protocol: Protocol,
    formData: any
  ) {
    const parsedFormData = await parseFormData(formData);

    axios
      .post('/api/maintenance', {
        deployedSensorId: sensor.id,
        protocolId: protocol.protocolId,
        data: parsedFormData,
      })
      .then(() => {
        history.push('/maintenance/success');
      })
      .catch((err) => {
        alert(
          'Er ging iets mis met het versturen van dit formulier. Probeer opnieuw of contacteer een beheerder.'
        );
        console.error('err');
      });
  }

  return render();

  function render() {
    return (
      <AppLayout>
        <StyledGrid container className="root">
          <Grid item xs={12} md={6} className="content-container">
            <Route path="/maintenance" exact>
              <Box bgcolor="#eee" m={0} p={4}>
                <Box m={0} mb={4}>
                  {renderLocationList()}
                </Box>
                <Box m={0} mb={4}>
                  {renderSensorList()}
                </Box>
                <Box m={0} mt={4}>
                  {renderProtocolList()}
                </Box>
              </Box>
              {activeSensor && activeProtocol && (
                <Box p={4}>
                  <MaintenanceForm
                    protocol={activeProtocol}
                    onSubmit={(formData: any) =>
                      handleSubmit(activeSensor, activeProtocol, formData)
                    }
                  />
                </Box>
              )}
            </Route>
            <Route path="/maintenance/success" exact>
              <MaintenanceSuccess />
            </Route>
          </Grid>
          <Grid item xs={false} md={6} className="map-container">
            <Map
              lat={position.lat}
              lng={position.lng}
              zoom={position.zoom}
              // onChange={(position) => console.log(position)}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&#169;<a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                zIndex={0}
              />

              {sensors?.map((sensor) => (
                <Marker
                  key={sensor.id}
                  position={[sensor.lat, sensor.lng]}
                  onClick={() => handleMarkerClick(sensor)}
                ></Marker>
              ))}
            </Map>
          </Grid>
        </StyledGrid>
      </AppLayout>
    );
  }

  function renderLocationList() {
    function handleChange(
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) {
      const location = event.target.value;
      if (location) setActiveLocation(location as string);
      setActiveSensor(null);
      setActiveProtocol(null);
    }

    return (
      <>
        <Typography>Selecteer de locatie</Typography>
        <FormControl className="formControl">
          <InputLabel id="maintenance-location">Locatie</InputLabel>
          <Select
            labelId="maintenance-location"
            id="maintenance-location-select"
            value={activeLocation ? activeLocation : ''}
            onChange={handleChange}
          >
            {locations!.map((location, i: number) => (
              <MenuItem value={location} key={`location-${i}`}>
                {location}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }
  function renderSensorList() {
    function handleChange(
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) {
      const sensor = filteredSensors!.find(
        (sensor) => sensor.id === event.target.value
      );
      if (sensor) setActiveSensor(sensor);
      setActiveProtocol(null);
    }

    if (!activeLocation) return null;

    return (
      <>
        <Typography>Selecteer de sensor</Typography>
        <FormControl className="formControl">
          <InputLabel id="maintenance-sensor">Sensor</InputLabel>
          <Select
            labelId="maintenance-sensor"
            id="maintenance-sensor-select"
            value={activeSensor ? activeSensor.id.toString() : ''}
            onChange={handleChange}
          >
            {filteredSensors!.map((sensor, i: number) => (
              <MenuItem value={sensor.id} key={sensor.id}>
                {sensor.sensorModelName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }

  function renderProtocolList() {
    function handleChange(
      event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
      }>
    ) {
      const protocol = activeSensor?.protocols.find(
        (protocol) => protocol.protocolId === event.target.value
      );
      if (protocol) setActiveProtocol(protocol);
      if (dummyProtocol.protocolId === event.target.value)
        setActiveProtocol(dummyProtocol);
    }

    if (!activeSensor) return null;

    const protocols = [
      ...activeSensor.protocols,
      ...(process.env.NODE_ENV === 'development' ? [dummyProtocol] : []),
    ];

    return (
      <>
        <Typography>Welk protocol werd uitgevoerd?</Typography>
        <FormControl className="formControl">
          <InputLabel id="maintenance-protocol">Protocol</InputLabel>
          <Select
            labelId="maintenance-protocol"
            placeholder="Kies een protocol"
            onChange={handleChange}
            value={activeProtocol?.protocolId || ''}
          >
            {protocols.map((protocol) => (
              <MenuItem
                key={`protocol-${protocol.protocolId}`}
                value={protocol.protocolId}
              >
                {protocol.schema.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    );
  }
}

const StyledGrid = styled(Grid)`
  padding: 0px;
  height: 100%;
  width: 100%;
  height: 100%;
  flex-grow: 1;

  .content-container {
    height: 100%;
    flex-grow: 1;
    overflow: auto;
  }

  .map-container {
    height: 100%;
    flex-grow: 1;
  }

  .formControl {
    min-width: 120px;
  }
`;

function isImage(data: string) {
  // if data is of type string, check if it is an image
  if (data.includes) {
    return data.includes('data:image/');
  }
  return false;
}

interface ParseItem {
  key: string;
  value: any;
}

async function resizeImage(data: string) {
  const imageConfig = {
    quality: 0.5,
    maxWidth: 800,
    maxHeight: 800,
    autoRotate: true,
    debug: true,
  };
  const fileBlob = dataURItoBlob(data);
  const resizedBlob = await readAndCompressImage(fileBlob, imageConfig);

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(resizedBlob);
    reader.onloadend = function () {
      var base64data = reader.result;
      resolve(base64data);
    };
  });
}

async function parseFormData(formData: any) {
  const promises = await Object.keys(formData).reduce(
    (promiseChain: Promise<any>, currentKey: string): Promise<ParseItem[]> => {
      return promiseChain.then(async (chainResults) => {
        const value = formData[currentKey];

        if (isImage(value)) {
          const resizedImage = await resizeImage(value);
          return [...chainResults, { key: currentKey, value: resizedImage }];
        } else {
          return [...chainResults, { key: currentKey, value }];
        }
      });
    },
    Promise.resolve([])
  );

  // flatten array into formData object
  const parsedFormData = promises.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: item.value }),
    {}
  );

  return parsedFormData;
}

function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  // var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab]);
  return bb;
}
