import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { auth } from 'marvin-auth-kit';
import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

interface Props {
  children: React.ReactElement;
}

export const AppLayout = (props: Props) => {
  const history = useHistory();

  function handleLogout() {
    auth.logout();
    history.push('/login');
  }

  return render();

  function render() {
    return (
      <Styles>
        <AppBar position="static" className="app-bar">
          <Toolbar>
            <Typography variant="h6" className="title">
              Stiemerlab
            </Typography>
            <Button color="inherit" onClick={() => handleLogout()}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <div className="content">{props.children}</div>
      </Styles>
    );
  }
};

const Styles = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .title {
    flex-grow: 1;
    font-weight: bold;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0;
    flex: 1;
    overflow: auto;
  }
`;
