import { Protocol } from '../types';

export const dummyProtocol: Protocol = {
  protocolId: -1,
  schema: {
    $schema: 'http://json-schema.org/schema#',
    $id: 'http://marvin.vito.be/iow/CTD_onderhoud.json',
    title: 'DUMMY - CTD sensor onderhoud',
    description:
      'In dit formulier staan de gegevens die geregistreerd moeten worden bij onderhoud van de CTD sensor en het overzicht van de uit te voeren handelingen. Raadpleeg de handleiding en het instructie filmpje voor meer details bij elke handeling.',
    type: 'object',
    required: [
      // 'operator',
      // 'verifyLocation',
      // 'verifySensor',
      // 'pictureArrival',
      // 'timeArrival',
      // 'tubeOutside',
      // 'tubeOutsideAction',
      // 'timeSensorOut',
      // 'sensorOut',
      // 'pictureSensorBefore',
      // 'sensorCleaning',
      'pictureSensorAfter',
      // 'tubeInside',
      // 'tubeInsideAction',
      // 'sensorIn',
      // 'timeFinished',
      'pictureFinished',
    ],
    properties: {
      // operator: {
      //   type: 'string',
      //   title: 'Uitvoerder van dit onderhoud (voornaam)',
      // },
      // verifyLocation: {
      //   type: 'boolean',
      //   title:
      //     'Verifieer of het nummer van de geselecteerde locatie overeenkomt met het nummer op het label van de sensorbuis',
      // },
      // verifySensor: {
      //   type: 'boolean',
      //   title:
      //     'Verifieer of de geselecteerde sensor overeenkomt met het sensortype (CTD) op het label van sensorbuis',
      // },
      // pictureArrival: {
      //   type: 'string',
      //   format: 'data-url',
      //   title:
      //     'Neem een foto bij aankomst van de volledige sensorbuis en het water rondom de buis',
      // },
      // timeArrival: {
      //   type: 'string',
      //   format: 'date-time',
      //   title: 'Noteer het tijdstip vóór de start van enige handeling',
      //   default: 'new Date().toISOString()',
      // },
      // tubeOutside: {
      //   type: 'string',
      //   title:
      //     'Is er materiaal (vuilnis, planten, takken,...) opgehoopt rond de buis?',
      //   enum: ['nee (weinig of geen opgehoopt materiaal', 'ja'],
      // },
      // tubeOutsideAction: {
      //   type: 'string',
      //   title:
      //     'Welke actie is genomen i.v.m. opgehoopt materiaal rond de buis?',
      //   enum: [
      //     'geen',
      //     'opgehoopt materiaal verwijderd',
      //     'opgehoopt materiaal gedeeltelijk verwijderd & melding',
      //     'melding',
      //   ],
      // },
      // timeSensorOut: {
      //   type: 'string',
      //   format: 'date-time',
      //   title:
      //     'Noteer het tijdstip vóór het openen van de buis om de sensor uit te halen.',
      //   default: 'new Date().toISOString()',
      // },
      // sensorOut: {
      //   type: 'boolean',
      //   title:
      //     'Maak het hangslot los, schroef het deksel open, en haal de sensor uit de buis.',
      // },
      // pictureSensorBefore: {
      //   type: 'string',
      //   format: 'data-url',
      //   title: 'Neem een foto van de sensor vóór schoonmaken',
      // },
      // sensorCleaning: {
      //   type: 'boolean',
      //   title:
      //     'Spoel de sensor af en maak de sensor schoon zoals beschreven in de handleiding. Hang de sensor na schoonmaken in zuiver water tijdens eventueel verder reinigen van de buis.',
      // },
      pictureSensorAfter: {
        type: 'string',
        format: 'data-url',
        title: 'Neem een foto van de sensor ná schoonmaken',
      },
      // tubeInside: {
      //   type: 'string',
      //   title:
      //     'Is er materiaal opgehoopt binnenin de sensorbuis (verstopping)?',
      //   enum: ['nee', 'ja'],
      // },
      // tubeInsideAction: {
      //   type: 'string',
      //   title: 'Welke actie is genomen i.v.m. opgehoopt materiaal in de buis?',
      //   enum: [
      //     'geen',
      //     'materiaal/verstopping verwijderd',
      //     'materiaal/verstopping gedeeltelijk verwijderd & melding',
      //     'melding',
      //   ],
      // },
      // sensorIn: {
      //   type: 'boolean',
      //   title:
      //     'Plaats de sensor terug in de buis, kijk na of het ophanghaakje vast zit, schroef het deksel dicht en maak het hangslot vast.',
      // },
      // timeFinished: {
      //   type: 'string',
      //   format: 'date-time',
      //   title:
      //     'Noteer het tijdstip na terugplaatsen van de sensor en afsluiten van de buis.',
      //   default: 'new Date().toISOString()',
      // },
      pictureFinished: {
        type: 'string',
        format: 'data-url',
        title:
          'Neem een foto bij vertrek van de volledige sensorbuis en het water rondom de buis',
      },
      // remarks: {
      //   type: 'string',
      //   title: 'Heb je nog opmerkingen, voeg die hieronder toe:',
      // },
    },
  },
  uiOptions: {},
};
