import React, { useState } from 'react';
import Form, { ISubmitEvent } from '@rjsf/core';
// import Form from '@rjsf/material-ui';
import { Protocol } from '../types';
import styled from 'styled-components';

const uiSchema = {
  remarks: { 'ui:widget': 'textarea' },
};

interface Props {
  protocol: Protocol;
  // TODO: type this better
  onSubmit: (data: ISubmitEvent<any>) => void;
}

export function MaintenanceForm(props: Props) {
  const { protocol } = props;
  const [formData, setFormData] = useState<any>();

  return render();

  function render() {
    if (protocol) return renderProtocol();
    // else
    return null;
  }

  function renderProtocol() {
    return (
      <Styles>
        <Form
          schema={protocol.schema}
          formData={formData}
          onChange={(result) => setFormData(result.formData)}
          onSubmit={(result) => props.onSubmit(result.formData)}
          uiSchema={{ ...uiSchema, ...protocol.uiOptions }}
        />
      </Styles>
    );
  }
}

// IMPORTANT: the applied form styling is because of material-ui not working properly for rjsf
//  more info: https://github.com/rjsf-team/react-jsonschema-form/issues/2004
const Styles = styled.div`
  fieldset {
    border: 0;
    padding: 0;
    margin: 0;

    legend {
      font-size: 1.5em;
    }
  }

  .form-group {
    & + .form-group {
      padding-top: 20px;
    }
  }

  .btn {
    margin-top: 20px;
    border: none;
    color: #fff;
    background-color: #3f51b5;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background-color: #303f9f;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
  }
`;
