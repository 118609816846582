import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import {
  createMuiTheme,
  CssBaseline,
  MuiThemeProvider,
} from '@material-ui/core';
import { SWRConfig } from 'swr';
import { Route, Router } from 'react-router';
import { ThemeProvider } from 'styled-components';
import history from './history';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { RootContainer } from './containers/RootContainer';

function fetcher(...args: any) {
  return fetch(args)
    .then((res) => res.json())
    .then((json) => json.data);
}

const theme = createMuiTheme();

ReactDOM.render(
  <SWRConfig
    value={{
      fetcher: fetcher,
    }}
  >
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div>loading...</div>}>
          <Router history={history}>
            <Route path="/" component={RootContainer} />
          </Router>
        </Suspense>
      </ThemeProvider>
    </MuiThemeProvider>
  </SWRConfig>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
